import { Quantity } from '../../molecules/invoice/SharedStyle'
import { GoPackage } from 'react-icons/go'
import { FaFontAwesomeFlag } from 'react-icons/fa'
import { ProductBucket } from '../../molecules/invoice/delivery/types'
import { OrderItem } from '../../molecules/invoice/purchase/types'

import {
  StyledColumnHeader,
  StyledColumnTitle,
  StyledContainer,
  StyledDescription,
  StyledEan,
  StyledHeader,
  StyledMain,
  StyledSectionName,
  StyledTitle,
  StyledTotalPrice,
  StyledTotalPriceContainer,
} from './styles/SectionDetails.style'
import { getProductDescription } from '../../../common/utils'

type ProductInfo = OrderItem | ProductBucket
export type ProductInfoList = (OrderItem | ProductBucket)[]

const LIST_ITEM_COLORS = ['#04153b', '#d1ab63', '#ff4a55']

function delivery(product: ProductInfo) {
  const {
    finalQuantityUnitary,
    isMultiPackage,
    productPackageQtd,
    finalPriceUnitary,
    targetQuantityUnitary,
    description,
    ean,
    unitEan,
    isNearExpiry,
  } = product || {}

  const isBox = isMultiPackage && !(finalQuantityUnitary % productPackageQtd) ? true : false

  const quantity = isBox
    ? Math.floor(finalQuantityUnitary / productPackageQtd)
    : finalQuantityUnitary

  const targetQuantity: number = isBox
    ? Math.floor(targetQuantityUnitary / productPackageQtd)
    : targetQuantityUnitary

  const descriptionProduct =
    isMultiPackage && finalQuantityUnitary % productPackageQtd
      ? description.split(')')[1]
      : description

  const quantityStatus = quantity == targetQuantity ? 0 : quantity == 0 ? 2 : 0

  const finalTotalPrice: number = isBox
    ? finalPriceUnitary * quantity * productPackageQtd
    : finalPriceUnitary * quantity
  const eanProduct = isMultiPackage ? ean : unitEan

  return {
    quantity: quantity,
    unitPrice: finalPriceUnitary,
    description: getProductDescription(descriptionProduct, isNearExpiry),
    isBox: isBox,
    quantityStatus: quantityStatus,
    color: LIST_ITEM_COLORS[quantityStatus],
    finalTotalPrice: finalTotalPrice,
    ean: eanProduct,
  }
}

function purchase(product: ProductInfo) {
  const {
    unitPrice,
    quantity,
    productDescription,
    isMultiPackage,
    productId,
    unitProductId,
    isNearExpiry,
  } = product || {}
  const isBox = isMultiPackage ? true : false
  const ean = parseInt(isBox ? productId : unitProductId)
  const finalTotalPrice = unitPrice * quantity
  const quantityStatus = 0

  return {
    quantity: quantity,
    unitPrice: unitPrice,
    description: getProductDescription(productDescription, isNearExpiry),
    isBox: isBox,
    finalTotalPrice: finalTotalPrice,
    color: LIST_ITEM_COLORS[0],
    ean: ean,
    quantityStatus: quantityStatus,
  }
}
function SectionDetailRow({ product, isDelivery }: { product: ProductInfo; isDelivery: boolean }) {
  const finalProduct = isDelivery ? delivery(product) : purchase(product)
  const { ean, quantityStatus, color, description, quantity, isBox, unitPrice, finalTotalPrice } =
    finalProduct || {}
  return (
    <StyledContainer>
      <StyledDescription>
        {!!quantityStatus && isDelivery && <FaFontAwesomeFlag size={15} style={{ color: color }} />}
        <div> {description} </div>
        <StyledEan>EAN: {ean}</StyledEan>
      </StyledDescription>

      <Quantity>
        {quantity} {isBox ? <GoPackage /> : ''}
      </Quantity>

      <Quantity>
        {Number(unitPrice).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Quantity>

      <Quantity>
        {finalTotalPrice.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Quantity>
    </StyledContainer>
  )
}

function SectionDetailItem({
  sectionName,
  productInfoList,
  isDelivery,
}: {
  sectionName: string
  productInfoList: ProductInfo[]
  isDelivery: boolean
}) {
  return (
    <>
      <StyledContainer>
        <StyledSectionName>{sectionName}</StyledSectionName>
      </StyledContainer>
      {productInfoList.map((productInfo, index) => (
        <SectionDetailRow product={productInfo} isDelivery={isDelivery} key={index} />
      ))}
    </>
  )
}

const SectionsDetails = ({
  productBuckets,
  isDelivery,
  isOutsourced,
}: {
  productBuckets: ProductInfoList
  isDelivery: boolean
  isOutsourced: boolean
}) => {
  const sections = [
    ...new Set(productBuckets.map((product: ProductInfo) => product?.section)),
  ].sort()

  const totalSum = productBuckets.reduce((sum, product) => {
    const finalProduct = isDelivery ? delivery(product) : purchase(product)
    return sum + (finalProduct?.finalTotalPrice || 0)
  }, 0)

  return (
    <StyledMain>
      <StyledHeader>
        <StyledTitle>{isOutsourced ? 'Produtos de parceiros' : 'Produtos Clubbi'}</StyledTitle>
        <StyledColumnHeader>
          <StyledColumnTitle>Qtde</StyledColumnTitle>
          <StyledColumnTitle>Valor unit.</StyledColumnTitle>
          <StyledColumnTitle>Total</StyledColumnTitle>
        </StyledColumnHeader>
      </StyledHeader>
      {sections
        .map(
          (section: string) =>
            [
              section,
              (productBuckets as ProductInfo[]).filter(
                (product: ProductInfo) => product.section == section
              ),
            ] as [string, ProductInfo[]]
        )
        .map(([sectionName, productsInfo]) => (
          <SectionDetailItem
            sectionName={sectionName}
            productInfoList={productsInfo}
            isDelivery={isDelivery}
            key={sectionName}
          />
        ))}
      <StyledTotalPriceContainer>
        <StyledSectionName>Total</StyledSectionName>
        <StyledTotalPrice>
          {totalSum.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </StyledTotalPrice>{' '}
      </StyledTotalPriceContainer>
    </StyledMain>
  )
}

export default SectionsDetails
