import { StyledNfeLink, StyledRow, StyledSubtitle } from './styles/DesktopLinkBoard.style'

import {
  PARTNER_NF_REQUEST_LABEL,
  PARTNER_NF_REQUEST_SUBLABEL,
  whatsappOutsourcedNfCXLinkGenerator,
} from '../../../common/constants'

import TwoDaysTag from '../../atoms/TwoDaysTag'

export const GarciaNfLink = ({
  merchantCode,
  shopperPlanId,
}: {
  merchantCode?: string
  shopperPlanId: string
}) => {
  return (
    <StyledNfeLink
      href={whatsappOutsourcedNfCXLinkGenerator(merchantCode, shopperPlanId)}
      target="_blank"
      rel="noreferrer"
    >
      <StyledRow>
        <span>{PARTNER_NF_REQUEST_LABEL}</span>
        <TwoDaysTag isProduct={false} />
      </StyledRow>
      <StyledSubtitle>{PARTNER_NF_REQUEST_SUBLABEL}</StyledSubtitle>
    </StyledNfeLink>
  )
}
