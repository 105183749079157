import { ButtonClubbi, clubbiStyled } from 'clubbi-ui'

export const StyledClubbiButton = clubbiStyled(ButtonClubbi)<{ isUpdate: boolean }>(
  ({ theme, isUpdate }) => ({
    backgroundColor: isUpdate ? theme.palette.brandGrey[50] : theme.palette.brandPurple[20],
    color: isUpdate ? theme.palette.brandWhite.main : theme.palette.brandBlack.main,
    textTransform: 'capitalize',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: isUpdate ? theme.palette.brandGrey[70] : theme.palette.brandPurple[30],
    },
  })
)

export const StyledButtonRow = clubbiStyled('div')(() => ({
  display: 'flex',
  gap: '8px',
  width: '100%',
  justifyContent: 'center',
}))
