import { ToggleButton, ToggleButtonGroup, clubbiStyled } from 'clubbi-ui'

export const StyledSelectionContainer = clubbiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '8px',
  padding: '0 16px',
  paddingTop: '24px',
  position: 'relative',
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledToggleButtonGroup = clubbiStyled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
  position: 'relative',
}))

export const StyledToggleButton = clubbiStyled(ToggleButton)<{ completedProgress: boolean }>(
  ({ theme, completedProgress }) => ({
    textTransform: 'none',
    width: '100%',
    padding: '8px 14px',
    fontSize: theme.typography.fontSize2XS,
    fontWeight: 400,
    borderRadius: '4px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    border: `1px solid ${theme.palette.brandGrey[20]} !important`,
    marginLeft: '1px !important',
    color: theme.palette.brandGrey[100],
    backgroundColor: theme.palette.brandWhite.main,
    '.days-to-delivery': {
      color: theme.palette.brandGrey[60],
      fontWeight: 400,
    },
    '.free-shipping': {
      color: completedProgress ? theme.palette.brandSuccess[70] : theme.palette.brandGrey[40],
      fontWeight: completedProgress ? 600 : 400,
    },

    '&.Mui-selected': {
      border: `1px solid ${theme.palette.brandPurple[60]} !important`,
      backgroundColor: theme.palette.brandWhite.main,
      color: theme.palette.brandBlack.main,
      fontWeight: 600,
    },
    '&.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.brandGrey[10],
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.fontSizeXXS,
      '.days-to-delivery': {
        fontSize: theme.typography.fontSize2XS,
      },
    },
  })
)

export const StyledType = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'initial',
  lineHeight: '16px',
}))
