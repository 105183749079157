import { clubbiStyled, Popover } from 'clubbi-ui'

export const StyledOptionButton = clubbiStyled('div')(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
}))

export const StyledWrapperButtons = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  display: 'none',
  fontFamily: `'${theme.typography.fontFamily}'`,
  justifyContent: 'flex-start',
  paddingTop: theme.spacing(5),
  marginBottom: theme.spacing(3.5),
  gap: '24px',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    gap: '24px',
    display: 'flex',
  },
}))

export const StyledNfeLink = clubbiStyled('a')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '239.2%',
  padding: '4px 16px',
  color: theme.palette.brandGrey[100],
  [theme.breakpoints.up('md')]: {
    padding: '4px 12px',
  },
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: 'rgb(240,240,240)',
  },
}))

export const StyledCollapseWrap = clubbiStyled('span')<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    color: disabled ? theme.palette.brandGrey.main : theme.palette.brandPurple.main,
    fontWeight: 700,
  })
)

export const StyledNFPopover = clubbiStyled(Popover)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  borderRadius: '4px',
  backgroundColor: 'transparent',
  marginRight: '4px',
  padding: '4px',
}))

export const StyledPopoverWrapper = clubbiStyled('div')(() => ({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  width: '210px',
}))

export const StyledRow = clubbiStyled('div')(() => ({
  textAlign: 'left',
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
}))

export const StyledSubtitle = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandGrey[100],
  fontWeight: 400,
  lineHeight: '107.2%',
  opacity: '0.5',
  paddingBottom: '8px',
}))
