import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Icon from '../../atoms/Icon'
import { StyledHelpLink } from './styles/InstantPaymentModal.style'
import { whatsappHelpCXLinkGenerator } from '../../../common/constants'
import { useAppSelector } from '../../../common/hooks'

interface Props {
  showModal: boolean
  hide: () => void
  submitOrder: () => void
}

const Checkbox = ({ submitOrder, hide }: { submitOrder: () => void; hide: () => void }) => {
  const [disabledButton, setDisabledButton] = useState(true)
  const { merchantCode } = useAppSelector((store) => store.session)

  const handleClick = () => {
    setDisabledButton(true)
    submitOrder()
    hide()
  }

  return (
    <>
      <Modal.Body>
        <p>
          Seu pedido deverá ser pago (via Pix) no momento do recebimento. No momento da entrega, uma
          pessoa responsável deve estar disponível para efetuar o pagamento, presencialmente ou
          através do WhatsApp.
        </p>
        <p>
          Se você tiver alguma dúvida, contate-nos pelo WhatsApp clicando{' '}
          <StyledHelpLink
            href={whatsappHelpCXLinkGenerator(merchantCode)}
            target={'_blank'}
            rel="noreferrer"
          >
            aqui
          </StyledHelpLink>
          .
        </p>
        <Form.Check
          data-testid="checkbox"
          type="checkbox"
          required
          onChange={() => {
            setDisabledButton(!disabledButton)
          }}
          label="Entendi e alguém estará disponivel para receber o pedido"
          name={'instantPayment'}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: '#7C00F4',
          }}
          onClick={handleClick}
          variant="primary"
          disabled={disabledButton}
        >
          <Icon.ShoppingCart size="1.5em" className="pb-1" />
          Confirmar
        </Button>
      </Modal.Footer>
    </>
  )
}

export const InstantPaymentModal = ({ showModal, hide, submitOrder }: Props) => {
  const { goBack, listen } = useHistory()
  const hideAndGoBack = useCallback(() => {
    hide()
    goBack()
  }, [goBack])

  useEffect(
    () =>
      listen(() => {
        if (showModal) {
          hide()
        }
      }),
    [showModal, hide, listen]
  )

  return (
    <Modal
      size={'lg'}
      onHide={hideAndGoBack}
      show={showModal}
      centered
      data-testid="instant-payment-modal"
    >
      <Modal.Header style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <img
          src={'/img/instant_payment_icon.png'}
          alt={'Ícone de pagamento à vista'}
          style={{ width: '82px', height: '68px', alignItems: 'center' }}
        />
        <h3>
          {' '}
          <b> Pagamento à vista </b>{' '}
        </h3>
      </Modal.Header>
      <Checkbox submitOrder={submitOrder} hide={hide} />
    </Modal>
  )
}
