import algoliasearch from 'algoliasearch/lite'
import { Link, useHistory } from 'react-router-dom'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { Autocomplete } from '../Algolia/Autocomplete'
import '@algolia/autocomplete-theme-classic'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import {
  debounced,
  END_BLACK_FRIDAY,
  END_ESQUENTA_BF,
  isExternalClubber,
  isWithinDateRange,
  START_BLACK_FRIDAY,
  START_ESQUENTA_BF,
} from '../../../common/utils'

import {
  ALGOLIA_APP_ID,
  ALGOLIA_APP_KEY,
  REACT_APP_ALGOLIA_DB,
  REACT_APP_INDEX_NAME,
} from '../../../common/AlgoliaAutocomplete.config'
import { useAppSelector } from '../../../common/hooks'
import { RecommendedProductsAlgolia } from '../recommendedCorridor/RecommendedSearchBarProducts'
import { getCustomProducts, getNeugebauerProducts } from '../../../services/productApi'
import { Product } from '../../../store/data/types'
import { ALGOLIA_RESULTS, HITS_PER_PAGE, SEARCH_RESULTS_QUANTITY } from '../../../common/constants'
import { ShoppingListButtonDesktop } from '../../atoms/ShoppingListButtonDesktop'
import { MissionsIcon } from './headerIcons/MissionsIcon'
import { missionProgressModalActions } from '../../../store/slices/missionProgressModal'
import { dispatch } from '../../../store'
import { MissionProgress } from '../MissionProgress'

import {
  StyledIconsHeader,
  StyledHeaderMain,
  StyledHeaderButton,
  StyledShoppingListButton,
  StyledSearchBar,
  StyledContainerIcons,
  StyledBFIcons,
} from './style/Header.style'
import {
  StyledAlgoliaItem,
  StyledHr,
  StyledSearchBarSectionsTitle,
} from '../recommendedCorridor/styles/RecommendedSearchBarProducts.style'
import { CartIconWithBadge } from '../Icons/CartIconWithBadge'
import { isMerchantBlocked } from '../../../common/utils'
import { recommendedDrawerActions } from '../../../store/slices/recommendedDrawer'
import { ProfileButton } from '../profile/ProfileButton'
import { MissionProgressModalWrapperDesktop } from '../styles/MissionProgress.style'

export const HeaderDesktop = () => {
  const [
    isLogged,
    isOpen,
    missions,
    { status, merchantCode, clubberArea, clubberEmail, cartSessionId },
  ] = useAppSelector((state) => [
    !!state.session.merchantCode,
    state.missionProgressModal.isOpen,
    state.missions,
    state.session,
  ])

  const [products, setProducts] = useState<Product[] | []>([])
  const [partnershipProducts, setPartnershipProducts] = useState<Product[] | []>([])

  const hasMission = !!missions?.length
  const history = useHistory()
  const searchClient = algoliasearch(ALGOLIA_APP_ID!, ALGOLIA_APP_KEY!)
  const isMissionProgressModalOpen = isOpen
  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))
  const isInCartPage = location.pathname === '/cart'
  const isClubber = !isExternalClubber(clubberArea)

  const getProducts = async () => {
    const customProducts = await getCustomProducts(SEARCH_RESULTS_QUANTITY)
    setProducts(customProducts)
  }

  const getPartnershipProducts = async () => {
    const result = await getNeugebauerProducts(clubberEmail!, cartSessionId, 0, 4)
    setPartnershipProducts(result.products)
  }

  useEffect(() => {
    if (!isClubber) {
      getPartnershipProducts()
    }
  }, [])

  useEffect(() => {
    getProducts()
  }, [history.location.pathname, history.location.search])

  const onSubmit = useCallback(({ state }: { state: any }) => {
    if (state.query) {
      history.replace({
        pathname: '/search',
        search: new URLSearchParams({ query: state.query }).toString(),
      })
    }
  }, [])

  const handleClickCart = () => {
    if (isLogged && !isMerchantBlocked(status)) {
      dispatch(recommendedDrawerActions.setIsOpen(true))
      dispatch(missionProgressModalActions.setIsOpen(false))
      if (!isClubber) {
        dispatch(recommendedDrawerActions.setIsOpen(false))
        history.replace('/cart')
      }
    }
  }
  const handleMissionExpandButtonClick = () => {
    dispatch(missionProgressModalActions.setIsOpen(!isMissionProgressModalOpen))
  }

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: 'searches',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ state }) {
            if (state.query) {
              history.replace({
                pathname: '/search',
                search: new URLSearchParams({ query: state.query }).toString(),
              })
            }
          },
          templates: {
            ...source.templates,
            header() {
              return (
                <>
                  <StyledHr />
                  <StyledSearchBarSectionsTitle>BUSCAS RECENTES</StyledSearchBarSectionsTitle>
                </>
              )
            },
          },
        }
      },
    })

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: REACT_APP_INDEX_NAME!,
      getSearchParams() {
        return recentSearches.data!.getAlgoliaSearchParams({
          hitsPerPage: HITS_PER_PAGE,
        })
      },

      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect({ state }) {
            if (state.query) {
              history.replace({
                pathname: '/search',
                search: new URLSearchParams({ query: state.query }).toString(),
              })
            }
          },
          getItems(params) {
            if (!params.state.query) {
              return []
            }
            const items = source.getItems(params)
            return items
          },
          templates: {
            ...source.templates,
            header() {
              return (
                <>
                  <StyledHr />
                  <StyledSearchBarSectionsTitle>SUGESTÕES</StyledSearchBarSectionsTitle>
                </>
              )
            },
          },
        }
      },
    })
    return [querySuggestions, recentSearches]
  }, [])

  const isEsquenta = isWithinDateRange(START_ESQUENTA_BF, END_ESQUENTA_BF)
  const isBlackFriday = isWithinDateRange(START_BLACK_FRIDAY, END_BLACK_FRIDAY)

  return (
    <StyledHeaderMain>
      <div>
        <Link to="/">
          {isEsquenta && (
            <StyledBFIcons>
              <img src={'/img/clubbi-logo-white.png'} alt="Logo Clubbi" width={80} />
              <img src={'/img/icon-fire.png'} alt="Icone de fogo" width={25} height={30} />
            </StyledBFIcons>
          )}
          {isBlackFriday && (
            <StyledBFIcons>
              <img src={'/img/clubbi-logo-white.png'} alt="Logo Clubbi" width={80} />
              <img src={'/img/icon-coin.png'} alt="Icone de moeda" width={30} height={30} />
            </StyledBFIcons>
          )}
          {!isEsquenta && !isBlackFriday && (
            <img src={'/img/clubbi-logo-white.png'} alt="Logo Clubbi" width={80} />
          )}
        </Link>
      </div>

      <StyledSearchBar>
        <Autocomplete
          translations={{ detachedCancelButtonText: 'Fechar' }}
          plugins={plugins}
          onSubmit={onSubmit}
          openOnFocus={true}
          insights={true}
          placeholder="Buscar produto"
          getSources={({ query }: { query: any }) =>
            debounced([
              {
                sourceId: 'products',
                getItems() {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: REACT_APP_ALGOLIA_DB!,
                        query,
                        params: {
                          hitsPerPage: ALGOLIA_RESULTS,
                        },
                      },
                    ],
                  })
                },
                templates: {
                  item() {
                    return (
                      <StyledAlgoliaItem>
                        {products ? (
                          <RecommendedProductsAlgolia
                            merchantCode={merchantCode}
                            products={isClubber ? products : partnershipProducts}
                            history={history}
                          />
                        ) : (
                          <></>
                        )}
                      </StyledAlgoliaItem>
                    )
                  },
                },
              },
            ])
          }
        />
      </StyledSearchBar>
      <StyledContainerIcons isLogged={isLogged}>
        {isLogged && (
          <StyledShoppingListButton>
            <ShoppingListButtonDesktop />
          </StyledShoppingListButton>
        )}
        <StyledIconsHeader>
          <ProfileButton />
          {hasMission && !isInCartPage && (
            <StyledHeaderButton
              onClick={handleMissionExpandButtonClick}
              isActive={isMissionProgressModalOpen}
              isDesktop={isDesktop}
            >
              <MissionsIcon />
            </StyledHeaderButton>
          )}
          {isLogged && hasMission && isMissionProgressModalOpen && (
            <MissionProgressModalWrapperDesktop>
              <MissionProgress />
            </MissionProgressModalWrapperDesktop>
          )}
          <CartIconWithBadge isSelectedCart={isInCartPage} handleClickCart={handleClickCart} />
        </StyledIconsHeader>
      </StyledContainerIcons>
    </StyledHeaderMain>
  )
}
