import { ConversionEventData } from '../components/organisms/Cart/interfaces'
import { getProductMaxOrderLimit } from '../store/data/cartFunctions'
import { CartItem, Product } from '../store/data/types'
import { TWO_DAYS_STORE } from './constants'

export const promotionalProducts = (products: Product[], promotionalEans: string[]) =>
  products.filter((item) => promotionalEans.includes(item.ean))

export const isInPromotionalDateRange = (today: Date) => {
  const promotionStartDate = new Date(2022, 10, 21)
  const promotionEndDate = new Date(2022, 10, 27, 23, 59)

  return today >= promotionStartDate && today <= promotionEndDate
}

export const getPackagePrice = (product: Product) => {
  const [firstSupplier] = product.supplierPrices
  const maxOrderLimit = getProductMaxOrderLimit(product)
  const isPackageAvailable = product.packageNumberOfItems && firstSupplier.packagePrice
  const noPackagePriceAvailable =
    !isPackageAvailable || product.packageNumberOfItems! > maxOrderLimit

  if (noPackagePriceAvailable) {
    return 0
  }
  if (firstSupplier.getMoreForLess) {
    return firstSupplier.getMoreForLess.priceWithoutPromotion
  }
  return firstSupplier.packagePrice
}

export const notEnoughPackageInStock = (product: Product) => {
  const firstSupplier = product.supplierPrices[0]
  const packageQuantity = product.packageNumberOfItems
  const inStockQuantity = firstSupplier.inStockQuantity
  if (packageQuantity && inStockQuantity) return inStockQuantity < packageQuantity
}

export const getPriceByMode = (isPackageMode: boolean, product: Product) => {
  if (isPackageMode) {
    return getPackagePrice(product)
  }

  const [firstSupplier] = product.supplierPrices
  return firstSupplier.price
}

export const getQuantityByMode = (cartItem: CartItem) => {
  if (cartItem.isPackageMode) {
    return cartItem.quantity * cartItem.product.supplierPrices[0].packageQtd!
  }
  return cartItem.quantity
}

export const getUnitPerPackagePrice = (
  packagePrice: number | undefined,
  packageNumberOfItems: number | undefined
) => {
  const isPackageAvailable = packagePrice && packageNumberOfItems

  if (isPackageAvailable) {
    return packagePrice / packageNumberOfItems
  }
  return undefined
}

export const getLowestUnitPrice = (product: Product): number => {
  const { supplierPrices } = product
  if (!supplierPrices[0].packagePrice) {
    return supplierPrices[0].price
  }
  const currencyPrice = supplierPrices[0].packagePrice / product.packageNumberOfItems!
  return Number(currencyPrice.toFixed(2))
}

export const getItemPrice = (item: CartItem) => {
  if (item.isPackageMode) {
    const packagePrice = item.product.supplierPrices[0].packagePrice
    const packageQuantity = item.product.supplierPrices[0].packageQtd
    return getUnitPerPackagePrice(packagePrice, packageQuantity)?.toFixed(3)
  }
  return item.product.supplierPrices[0].price
}

export const retailMediaItemBuilder = (itemsData: CartItem[]) =>
  itemsData.map((item) => {
    return {
      sku: item.product.ean,
      seller_id: item.product.supplierPrices[0].supplierId,
      product_id: item.product.id,
      quantity: getQuantityByMode(item),
      price: Number(getItemPrice(item)),
      promotional_price: Number(getItemPrice(item)),
    }
  })

export const formatRetailMediaData = (
  id: string,
  itemsData: CartItem[],
  cartSessionId: string,
  merchantCode?: string
): ConversionEventData => {
  return {
    channel: 'ecommerce',
    publisher_id: process.env.REACT_APP_NEWTAIL_PUBLISHER_ID,
    user_id: merchantCode,
    session_id: cartSessionId,
    order_id: id,
    items: retailMediaItemBuilder(itemsData),
    created_at: new Date().toISOString(),
  }
}

const checkTwoDaysItems = (cartItems: CartItem[], method: 'some' | 'every') => {
  return cartItems[method]((cartItem) => {
    const { supplierId } = cartItem.product.supplierPrices[0]
    return supplierId === TWO_DAYS_STORE
  })
}

export const hasTwoDaysItems = (cartItems: CartItem[]) => checkTwoDaysItems(cartItems, 'some')

export const hasOnlyTwoDaysItems = (cartItems: CartItem[]) => checkTwoDaysItems(cartItems, 'every')
