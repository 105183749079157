import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'

import { shopperPlanApi } from '../../../../services/shopperPlanApi'
import { InvoiceContainer, StyledTab, StyledButton } from '../../../molecules/invoice/SharedStyle'
import InvoiceDetails from '../InvoiceDetails'
import AcceptModal from '../../../molecules/invoice/delivery/AcceptModal'
import { ProductBucket as ProductBucketsInterface } from '../../../molecules/invoice/delivery/types'

type FormType = {
  name: string
  cpf: string
}

interface Props {
  pickingData: any
  query: string
  planId: string
}

const productBucketsWithFilter = (
  products: ProductBucketsInterface[]
): ProductBucketsInterface[] => {
  return products.map((product: ProductBucketsInterface) => {
    if (product.finalQuantityUnitary === 0) {
      product.section = 'Produtos não entregues'
    }
    return product
  })
}

const _Delivery = ({ pickingData, planId }: Props) => {
  const productBuckets = productBucketsWithFilter(pickingData.customerDetailedResults)
  const customer = pickingData.customerConsolidatedResults
  const payment = customer.paymentType
  const orderIds = pickingData.customerConsolidatedResults.orderIds
  const creationDatetime = new Date(customer.shopperPlanCreationDatetime)
  const deliveryFee = customer.deliveryFee

  return (
    <InvoiceDetails
      productBuckets={productBuckets}
      customer={customer}
      orderIds={orderIds}
      creationDatetime={creationDatetime}
      payment={payment}
      planId={planId}
      isDelivery={true}
      deliveryFee={deliveryFee}
    />
  )
}

const WrapTab = ({ pickingData, planId }: Props) => {
  const [key, setKey] = useState<string>('v1')
  const [isLoading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState<FormType>({ name: '', cpf: '' })
  const [errors, setErrors] = useState<FormType>({ name: '', cpf: '' })
  const [validated, setValidated] = useState(false)
  const [message, setMessage] = useState('')
  const [updateMessage, setUpdateMessage] = useState('')
  const [showAcceptButton, setShowAcceptButton] = useState(true)
  const [enableAcceptButton, setEnableAcceptButton] = useState(true)
  const [page, setPage] = useState(0)
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)

  const neutralizeBack = () => {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href)
      setIsModalOpened(false)
      revivalBack()
    }
  }

  const revivalBack = () => {
    window.onpopstate = null
    window.history.back()
  }

  const findFormErrors = () => {
    const { name, cpf } = formValue
    const newErrors: FormType = { name: '', cpf: '' }

    if (name === '') newErrors.name = 'Por favor, preencha seu nome'

    if (cpf.length !== 5) newErrors.cpf = 'Por favor, digite os 5 primeiros dígitos do seu CPF'

    return newErrors
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      const newErrors = findFormErrors()
      setValidated(true)

      if (newErrors.name !== '' || newErrors.cpf !== '') {
        setErrors(newErrors)
      } else {
        setLoading(true)
        await shopperPlanApi(
          pickingData.customerConsolidatedResults.clientSiteCode,
          planId,
          formValue
        )
        setLoading(false)
        setPage(page + 1)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target

    if (target.name === 'cpf' && target.value.length === 5) setErrors({ ...errors, cpf: '' })

    if (target.name === 'cpf' && target.value.length >= 5) target.value = target.value.slice(0, 5)

    if (target.name === 'name' && target.value.length) setErrors({ ...errors, name: '' })

    setFormValue((state) => ({
      ...state,
      [target.name]: target.value,
    }))
  }

  useEffect(() => {
    if (pickingData.customerConsolidatedResults.invoiceSignatureComplete) {
      setUpdateMessage('Houveram modificações desde a última assinatura')
      if (
        pickingData.customerDetailedResults
          .map(
            (bucket: { invoiceCustomerSignatureQuantity: any; deliveredQuantity: any }) =>
              bucket.invoiceCustomerSignatureQuantity == bucket.deliveredQuantity
          )
          .every(Boolean)
      ) {
        setUpdateMessage(``)
        setShowAcceptButton(false)
      }
      setMessage(
        `Fatura aceita por ${pickingData.customerConsolidatedResults.invoiceSignatureName}`
      )
    } else {
      setMessage('Fatura ainda não aceita')
    }
    if (!pickingData.customerConsolidatedResults.deliveryFinished) {
      setMessage('Não é possível aceitar a fatura enquanto o pedido não estiver entregue')
      setEnableAcceptButton(false)
    }
    if (
      pickingData.customerConsolidatedResults.isInstantPayment &&
      !pickingData.customerConsolidatedResults.isBillingPaid
    ) {
      setMessage('Cobrança precisa ser paga para aceitar a fatura')
      setEnableAcceptButton(false)
    }
  }, [pickingData])

  return (
    <>
      <InvoiceContainer>
        <Container>
          <StyledTab.Container id="tabs" activeKey={key} onSelect={(k) => setKey(k || 'v1')}>
            <Col className="d-flex flex-column">
              <Col className="m-2" style={{ backgroundColor: 'white' }}>
                <_Delivery pickingData={pickingData} planId={planId} query={''} />
              </Col>
              <Row className="d-flex justify-content-end">
                <div style={{ marginRight: 100 }}>
                  <p style={{ margin: '20px' }}>{message}</p>
                  <p style={{ margin: '20px' }}>{updateMessage}</p>
                  {showAcceptButton && (
                    <StyledButton
                      variant={enableAcceptButton ? 'success' : 'primary'}
                      className={'font-weight-bold'}
                      onClick={() => setIsModalOpened(true)}
                      disabled={!enableAcceptButton}
                    >
                      <FaCheck /> Aceitar
                    </StyledButton>
                  )}
                </div>
              </Row>
            </Col>
          </StyledTab.Container>
        </Container>
      </InvoiceContainer>
      {isModalOpened && (
        <AcceptModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          neutralizeBack={neutralizeBack}
          revivalBack={revivalBack}
          validated={validated}
          errors={errors}
          isLoading={isLoading}
          page={page}
        />
      )}
    </>
  )
}

export default WrapTab
