import React from 'react'
import { Communication } from './SharedStyle'

import { CX_WHATSAPP_NUMBER } from '../../../common/constants'

interface Props {
  isDelivery: boolean
}

const HeaderCommunication = ({ isDelivery }: Props) => {
  return (
    <Communication>
      <ul>
        {isDelivery ? (
          <li>
            A <b>Fatura</b> deve refletir exatamente aquilo que foi entregue, pedimos que valide com
            cuidado antes de dar o aceite
          </li>
        ) : (
          <></>
        )}

        <li>
          A Nota Fiscal do seu pedido estará disponível na aba de <b>Minhas Entregas</b>, e também
          será enviada por e-mail, assim que for emitida
        </li>
        <li>
          Quaisquer problemas ou mudanças necessárias, é só falar com o nosso atendimento clicando{' '}
          <a
            href={`https://api.whatsapp.com/send/?phone=${CX_WHATSAPP_NUMBER}&text&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noreferrer"
          >
            aqui
          </a>
        </li>
      </ul>
    </Communication>
  )
}

export default HeaderCommunication
