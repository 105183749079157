import React from 'react'
import { Customer as customerDelivery } from './delivery/types'
import { Customer as customerPurchase, OrderItem } from './purchase/types'
import { BorderBold, EndCommunication, InformationBottom, Thanks } from './SharedStyle'
import DeliveryPriceDetails from './delivery/PriceDetails'
import PurchasePriceDetails from './purchase/PriceDetails'
import {
  StyledButtonRow,
  StyledClubbiButton,
} from '../../organisms/invoice/styles/InvoiceDetails.style'

interface Props {
  isDelivery: boolean
  customer: customerDelivery | customerPurchase
  productBuckets: OrderItem[]
  deliveryFee: number
}

const InvoiceBottom = ({ isDelivery, customer, productBuckets, deliveryFee }: Props) => {
  return (
    <>
      <InformationBottom>
        {isDelivery ? (
          <DeliveryPriceDetails customer={customer} />
        ) : (
          <PurchasePriceDetails
            productBuckets={productBuckets as OrderItem[]}
            deliveryFee={deliveryFee}
          />
        )}
        <BorderBold />
        <Thanks>Obrigado!</Thanks>
        <StyledButtonRow>
          <StyledClubbiButton
            onClick={() => {
              window.location.reload()
            }}
            label="Atualizar"
            typeButton="filled"
            size="small"
            isUpdate
          />
          <StyledClubbiButton
            onClick={() => {
              window.print()
            }}
            label="Imprimir"
            typeButton="filled"
            size="small"
            isUpdate={false}
          />
        </StyledButtonRow>
      </InformationBottom>
      <EndCommunication>
        Você tem até 1 dia após o recebimento para solicitar troca ou devolução dos produtos
      </EndCommunication>
    </>
  )
}

export default InvoiceBottom
