import { Container, Col } from 'react-bootstrap'
import React from 'react'

import { InvoiceContainer } from '../../../molecules/invoice/SharedStyle'
import InvoiceDetails from '../InvoiceDetails'
import { Orders, Order, Customer } from '../../../molecules/invoice/purchase/types'

interface Props {
  pickingData: Orders
  query: string
  planId: string
  customer: Customer
}

const convertPaymentType = (type: number): string => {
  const types = ['Boleto', 'Cartão de crédito', 'Pix', 'Transferência']
  return types[type]
}

const allOrderItems = (orders: Order[]) => {
  const validItems = orders.map(({ items }: Order) => {
    return items.filter(
      (product: { category: string }) =>
        product.category !== 'Produtos Adicionados' && product.category !== 'Produtos Substituidos'
    )
  })
  return Array.prototype.concat(...validItems)
}

const _Purchase = ({ pickingData, planId, customer }: Props) => {
  const productBuckets = allOrderItems(pickingData.orders)
  const payment = convertPaymentType(pickingData.orders[0].paymentType)
  const orderIds = pickingData.orders.map((order: any) => order.id)
  const creationDatetime = new Date(pickingData.orders[0].orderDatetime)
  const deliveryFee = pickingData.orders[0].deliveryFee

  return (
    <InvoiceDetails
      productBuckets={productBuckets}
      customer={customer}
      orderIds={orderIds}
      creationDatetime={creationDatetime}
      payment={payment}
      planId={planId}
      isDelivery={false}
      deliveryFee={deliveryFee}
    />
  )
}

const WrapTab = ({ pickingData, planId, customer }: Props) => {
  return (
    <>
      <InvoiceContainer>
        <Container>
          <Col className="m-2" style={{ backgroundColor: 'white' }}>
            <_Purchase pickingData={pickingData} customer={customer} planId={planId} query={''} />
          </Col>
        </Container>
      </InvoiceContainer>
    </>
  )
}

export default WrapTab
