import { shippingInfosActions } from '../../../store/slices/ShippingInfos'
import { Money } from '../../atoms'
import { DELIVERY_TYPES, DELIVERY_TYPE_ENUM } from '../../../common/constants'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import gtm from '../../../common/gtmTracker'

import {
  StyledToggleButton,
  StyledToggleButtonGroup,
  StyledType,
} from './styles/ShippingTypes.style'
import { hasOnlyTwoDaysItems } from '../../../common/productUtils'
import { useEffect } from 'react'

export const ShippingTypesSelector = () => {
  const [deliveryType, completedProgress, { merchantCode, clubberEmail }] = useAppSelector(
    (state) => [
      state.shippingInfos.shippingType,
      state.shippingInfos.completedProgress,
      state.session,
    ]
  )

  const { cart } = useAppSelector((state) => state)
  const cartItems = Object.values(cart).filter((item) => item.quantity > 0)
  const isOnlyTwoDays = hasOnlyTwoDaysItems(cartItems)

  const dispatch = useAppDispatch()

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newType: DELIVERY_TYPE_ENUM) => {
    dispatch(shippingInfosActions.setShippingType({ shippingType: newType, completedProgress }))
    if (newType) {
      gtm.triggerClickShippingTypes(merchantCode, newType, clubberEmail)
    }
  }

  useEffect(() => {
    if (isOnlyTwoDays && deliveryType !== DELIVERY_TYPE_ENUM.free) {
      dispatch(
        shippingInfosActions.setShippingType({
          shippingType: DELIVERY_TYPE_ENUM.free,
          completedProgress,
        })
      )
    }
  }, [isOnlyTwoDays])

  return (
    <StyledToggleButtonGroup
      value={deliveryType}
      onChange={handleChange}
      exclusive
      data-testid="toggle-group"
    >
      <StyledToggleButton
        value={DELIVERY_TYPE_ENUM.fast}
        disabled={hasOnlyTwoDaysItems(cartItems)}
        completedProgress={completedProgress}
        data-testid="fast-shipping"
      >
        <StyledType>
          <div>Rápida</div>
          <div className="days-to-delivery">
            Você receberá duas entregas em dias diferentes. Seus produtos Clubbi serão entregues no
            próximo dia útil, seus produtos Parceiro serão entregues em dois dias úteis.
          </div>
        </StyledType>
        <Money
          amount={DELIVERY_TYPES.fast.value}
          isBold={deliveryType === DELIVERY_TYPE_ENUM.fast}
        />
      </StyledToggleButton>
      <StyledToggleButton
        value={DELIVERY_TYPE_ENUM.free}
        completedProgress={true}
        data-testid="free-shipping"
      >
        <StyledType>
          <div>Padrão</div>
          <div className="days-to-delivery">
            Você receberá tudo em uma única entrega, no prazo de 2 dias uteis.
          </div>
        </StyledType>
        {DELIVERY_TYPES.free.value === 0 && <span className="free-shipping">Grátis</span>}
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  )
}
