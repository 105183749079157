import {
  StyledSelectionContainer,
  StyledPaymentTextContainer,
  StyledTitle,
} from './styles/PaymentSelector.style'
import { PaymentSelectorProps, SelectionType } from './interfaces'
import { ClubbiToggleButtonGroup } from './ToggleButtonGroup'
import { WalletIcon } from 'clubbi-ui'
import { useContext, useEffect, useState } from 'react'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'
import {
  SSA_USERS_WITH_BOTH_INVOICE_TYPES,
  instantPaymentData,
  instantPaymentMessage,
  invoiceTypesData,
  noDeadlineAvailableMessage,
  paymentTypesData,
} from '../../../common/constants'
import { useAppSelector } from '../../../common/hooks'
import { getDeadlineMessage } from '../../../common/utils'
import { hasTwoDaysItems } from '../../../common/productUtils'

export const PaymentSelector = ({ type, setFormValue, formValue }: PaymentSelectorProps) => {
  const isPayment = type === SelectionType.PAYMENT
  const [paymentText, setPaymentText] = useState<string | null>(noDeadlineAvailableMessage)
  const session = useAppSelector((state) => state.session)
  const { isInstantPayment } = session
  const noSelected = formValue[type] === -1

  const { cart } = useAppSelector((state) => state)
  const cartItems = Object.values(cart).filter((item) => item.quantity > 0)

  useEffect(() => {
    if (isInstantPayment) {
      setPaymentText(instantPaymentMessage)
    } else if (session.paymentDeadline) {
      setPaymentText(getDeadlineMessage(session?.paymentDeadline))
    } else {
      setPaymentText(noDeadlineAvailableMessage)
    }
  }, [session.paymentDeadline, formValue.paymentType])

  const { isReady } = useContext(SplitContext)
  const treatmentsInvoiceType = useTreatments([SSA_USERS_WITH_BOTH_INVOICE_TYPES])

  const getAdjustedLabels = () => {
    if (isPayment) return isInstantPayment ? instantPaymentData : paymentTypesData

    const danfeId = 2
    const { treatment: treatmentInvoiceType } =
      treatmentsInvoiceType[SSA_USERS_WITH_BOTH_INVOICE_TYPES]
    const isInvoiceTypeSplitOff = isReady && treatmentInvoiceType === 'off'
    if (isInvoiceTypeSplitOff) {
      return invoiceTypesData.filter(({ id }) => id === danfeId)
    }
    return invoiceTypesData
  }

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    if (value !== null) {
      const target = event.currentTarget
      const name = target.parentElement?.id || ''

      setFormValue((state) => ({
        ...state,
        [name]: value,
        merchantCodeInvalid: name == 'merchantCode' ? false : state.merchantCodeInvalid,
      }))
    }
  }

  const hasSingleOption = getAdjustedLabels().length === 1

  const hasTwoDaysProducts = hasTwoDaysItems(cartItems)

  useEffect(() => {
    if (hasSingleOption && type === SelectionType.INVOICE && formValue[type] === -1) {
      setFormValue((state) => ({
        ...state,
        [type]: getAdjustedLabels()[0].id,
      }))
    }
  }, [hasSingleOption, type, formValue[type]])

  return (
    <StyledSelectionContainer>
      <StyledTitle noSelected={noSelected}>
        {isPayment ? 'Método de pagamento' : 'Tipo de documento fiscal'}
      </StyledTitle>
      <ClubbiToggleButtonGroup
        id={type}
        value={formValue[type]}
        hasIcon={isPayment}
        paymentLabels={getAdjustedLabels()}
        onChange={handleChange}
      />
      {isPayment && (
        <StyledPaymentTextContainer>
          <WalletIcon />
          <span>{paymentText}</span>
        </StyledPaymentTextContainer>
      )}
      {!isPayment && hasTwoDaysProducts && (
        <StyledPaymentTextContainer>
          <img src={'img/error-outline.png'} alt="Icone de atenção" width={25} />
          <span>Você receberá mais de uma Nota Fiscal nesta compra</span>
        </StyledPaymentTextContainer>
      )}
    </StyledSelectionContainer>
  )
}
