import { clubbiStyled } from 'clubbi-ui'

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  borderRadius: '4px 4px 0px 0px',
  backgroundColor: theme.palette.brandGrey[10],
  width: '100%',

  [theme.breakpoints.up('md')]: {
    marginTop: '0',
  },
}))

export const StyledProgressBar = clubbiStyled('div')(({ theme }) => ({
  padding: ' 0 12px 8px 8px',

  [theme.breakpoints.up('sm')]: {
    padding: '0 12px 12px',
  },
}))

export const StyledTitleContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.brandGrey[20]}`,
  fontWeight: 600,
  padding: '8px 12px',
  fontSize: theme.typography.fontSizeXXS,
  color: theme.palette.brandGrey[100],
  border: `1px solid ${theme.palette.brandGrey[20]}`,

  span: {
    color: theme.palette.brandPurple[60],
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    padding: '12px',
  },
}))

export const StyledMissionContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 12px',
  fontSize: theme.typography.fontSize2XS,
  color: theme.palette.brandGrey[100],

  span: {
    color: theme.palette.brandPurple[60],
  },

  button: {
    height: '25px',
    textTransform: 'none',
    fontSize: theme.typography.fontSize3XS,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSizeXS,
    padding: '12px',

    button: {
      height: '30px',
      textTransform: 'none',
      fontSize: theme.typography.fontSizeXXS,
      fontWeight: 600,
    },
  },
}))

export const StyledBody = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandWhite.main,
}))

export const StyledRemainingMissionCompletionValue = clubbiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandWarning[10],
  color: theme.palette.brandWarning[50],
  width: '107px',
  height: '16px',
  borderRadius: '38px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.typography.fontSizeXXS,

  strong: {
    paddingLeft: '2px',
    whiteSpace: 'nowrap',
  },
}))

export const StyledCompleteTag = clubbiStyled('div')(({ theme }) => ({
  height: '16px',
  borderRadius: '38px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.typography.fontSizeXXS,
  whiteSpace: 'nowrap',

  backgroundColor: theme.palette.brandSuccess[10],
  color: theme.palette.brandSuccess[80],

  strong: {
    paddingLeft: '2px',
    whiteSpace: 'nowrap',
  },
}))

export const StyledValueContent = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: ' 0 12px 8px 8px',

  [theme.breakpoints.up('sm')]: {
    padding: '0 12px 12px',
  },
}))

export const StyledCurrency = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize3XS,
  color: theme.palette.brandGrey[100],
  fontWeight: 600,
}))

export const StyledProgress = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXS,
  color: theme.palette.brandGrey[100],
  fontWeight: 600,
}))

export const StyledTotal = clubbiStyled('span')(({ theme }) => ({
  color: theme.palette.brandGrey[100],
  fontSize: theme.typography.fontSizeXS,
}))

export const MissionProgressModalWrapperDesktop = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: '800px',
  top: '150px',
  width: '600px',
  paddingLeft: '30px',
  zIndex: '10',
  marginTop: '8px',
  boxShadow: '0px -6px 20px 0px rgba(0, 0, 0, 0.20)',

  [theme.breakpoints.down('md')]: {
    bottom: '58px',
    paddingLeft: '8px',
  },
}))

export const MissionProgressModalWrapper = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '140px',
  width: '100%',
  zIndex: '10',
  boxShadow: '0px -6px 20px 0px rgba(0, 0, 0, 0.20)',

  [theme.breakpoints.down('md')]: {
    bottom: '58px',
    margin: '8px',
  },
}))
