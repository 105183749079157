import React, { ComponentType, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAppSelector } from '../../common/hooks'
import { clubbiStyled } from 'clubbi-ui'

export interface LoadablePageProps<T> {
  getInitialData: () => T | null
}

interface Props<T> extends LoadablePageProps<T> {
  dataFetch: any
  children: ComponentType<T>
  header?: boolean
  footer?: boolean
}

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  position: 'fixed',
  left: 'calc(50% - 4em)',
  top: 'calc(40% - 4em)',
  width: '8em',
  height: '8em',
}))

const StyledLoadablePage = clubbiStyled('div')(({ theme }) => ({
  marginBottom: '50px',
  minHeight: '100vh',

  [theme.breakpoints.up('md')]: {
    marginBottom: '100px',
  },
}))

export const _LoadablePage = <T,>({ getInitialData, dataFetch, children }: Props<T>) => {
  const [data, setData] = useState(null as T | null)
  const [error, setError] = useState<any>(null)
  const { regionId, merchantCode } = useAppSelector((state) => state.session)

  useEffect(() => {
    const _data = getInitialData()

    let controller: AbortController | null = new AbortController()
    if (_data) {
      setData(_data)
    } else {
      dataFetch(controller).then(setData).catch(setError)
      controller = null
    }
    return () => controller?.abort()
  }, [regionId, merchantCode])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (error) {
    throw error
  }

  const Children = children

  return (
    <StyledLoadablePage>
      {data ? (
        <Children {...data} />
      ) : (
        <StyledSpinner animation={'border'} role={'spinbutton'}>
          <span className="sr-only">Carregando...</span>
        </StyledSpinner>
      )}
    </StyledLoadablePage>
  )
}

export const LoadablePage = _LoadablePage
