import { useEffect, useState } from 'react'
import { ProgressBar, themeClubbi } from 'clubbi-ui'

import { useAppSelector } from '../../common/hooks'
import { getMissionProgress } from '../../common/missions'
import gtm from '../../common/gtmTracker'
import { Mission } from '../../store/data/types'

import {
  StyledBody,
  StyledCompleteTag,
  StyledCurrency,
  StyledMain,
  StyledMissionContent,
  StyledProgress,
  StyledRemainingMissionCompletionValue,
  StyledTitleContent,
  StyledTotal,
  StyledValueContent,
  StyledProgressBar,
} from './styles/MissionProgress.style'

export const MissionProgress = (): React.ReactElement => {
  const [currentProgress, setCurrentProgress] = useState<number>(0)
  const [triggedStartedMission, setTriggedStartedMission] = useState(false)
  const [triggedCompletedMission, setTriggedCompletedMission] = useState(false)

  const [{ merchantCode, clubberEmail }, missions, cart] = useAppSelector((state) => [
    state.session,
    state.missions,
    state.cart,
  ])

  const rankingMissions = () => {
    const missionsRanking: Mission[] | [] = missions ? [...missions] : []

    missionsRanking?.sort((a: Mission, b: Mission) => {
      if (!a.ranking) return 1
      if (!b.ranking) return -1
      if (a.ranking === b.ranking) {
        return a.updatedAt < b.updatedAt ? 1 : -1
      }
      return a.ranking - b.ranking
    })

    return missionsRanking
  }

  const sortedMissions: Mission[] | [] = rankingMissions()

  const priorityMission = sortedMissions?.length ? sortedMissions[0] : undefined

  const MIN = 0
  const MAX = priorityMission?.minOrderAmount || 0
  const selectedMission = priorityMission?.customerIds[0].selected

  const remainingMissionCompletionValue = MAX - currentProgress!

  useEffect(() => {
    if (priorityMission) {
      const result = getMissionProgress(priorityMission!, cart)
      if (result) {
        setCurrentProgress(result)
      }
    }
  }, [cart, missions])

  const handleLimits = (value: number) => {
    if (value < MIN) {
      return MIN
    } else if (value > MAX) {
      return MAX
    } else {
      return value
    }
  }

  const handleProgressBar = () => {
    if (currentProgress! < MIN) {
      return 0
    } else if (currentProgress! > MAX) {
      return 100
    } else {
      return ((currentProgress! - MIN) * 100) / (MAX - MIN)
    }
  }

  const completedMissionValue = currentProgress! >= MAX

  const handleMission = () => {
    const inProgress = currentProgress !== 0 && currentProgress < MAX
    if (inProgress && !triggedStartedMission) {
      gtm.triggerMissionStart(priorityMission!, merchantCode, clubberEmail)
      setTriggedStartedMission(true)
    }
    if (completedMissionValue && !triggedCompletedMission) {
      gtm.triggerMissionCompleted(priorityMission!, merchantCode, clubberEmail)
      setTriggedCompletedMission(true)
    }
  }

  useEffect(() => {
    if (merchantCode && priorityMission) {
      handleMission()
    }
  }, [currentProgress, completedMissionValue, merchantCode])

  const getDiscountText = () => {
    let text = 'Você ganhou o '
    if (priorityMission) {
      if (!selectedMission) text = 'Ative a missão para ganhar o '
    }
    return text
  }

  return (
    <>
      {!!priorityMission && (
        <StyledMain data-testid="mission-progress-main">
          <StyledTitleContent>
            {priorityMission.type === 'FREE_SHIPPING' && (
              <div>
                🚚 Garanta <span>frete grátis</span> para essa compra
              </div>
            )}
            {priorityMission.type === 'FIXED_VALUE' && (
              <div>
                💰 Garanta <span>descontos</span> para essa compra
              </div>
            )}
          </StyledTitleContent>
          <StyledBody>
            <StyledMissionContent>
              <div>{priorityMission.description}</div>
            </StyledMissionContent>
            <StyledValueContent>
              {completedMissionValue ? (
                <StyledCompleteTag>
                  {getDiscountText()}
                  <strong>
                    {priorityMission!.type === 'FREE_SHIPPING' ? 'frete grátis' : 'desconto'}
                  </strong>
                </StyledCompleteTag>
              ) : (
                <StyledRemainingMissionCompletionValue>
                  Faltam{' '}
                  <strong>{`R$ ${handleLimits(remainingMissionCompletionValue).toFixed(
                    2
                  )}`}</strong>
                </StyledRemainingMissionCompletionValue>
              )}
              {completedMissionValue ? (
                <></>
              ) : (
                <div>
                  <StyledCurrency>R$</StyledCurrency>
                  <StyledProgress>{handleLimits(currentProgress!).toFixed(2)}</StyledProgress>
                  <StyledTotal data-testid="min-order-amount">{`/${MAX}`}</StyledTotal>
                </div>
              )}
            </StyledValueContent>
            <StyledProgressBar>
              <ProgressBar
                value={handleProgressBar()}
                // @ts-ignore
                color={
                  completedMissionValue
                    ? themeClubbi.palette.brandSuccess[70]
                    : themeClubbi.palette.brandWarning[50]
                }
              />
            </StyledProgressBar>
          </StyledBody>
        </StyledMain>
      )}
    </>
  )
}
