import { InvoiceSummary } from '../../../store/data/types'
import { generateLinkOptionsFromInvoice } from '../../../common/utils'
import { DesktopLinkBoard } from './DesktopLinkBoard'
import { MobileLinkBoard } from './MobileLinkBoard'

export interface Option {
  label: string
  subLabel?: string
  disabled: boolean
  subOptions: Option[]
  url?: string
}

interface Props {
  invoice: InvoiceSummary
  merchantCode?: string
}

export const InvoiceCardLinkBoard = ({ invoice, merchantCode }: Props) => {
  const options = generateLinkOptionsFromInvoice(invoice, merchantCode)
  return (
    <>
      <DesktopLinkBoard options={options} invoice={invoice} />
      <MobileLinkBoard options={options} invoice={invoice} />
    </>
  )
}
