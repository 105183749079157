import { clubbiStyled } from 'clubbi-ui'

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.brandGrey[10],
  border: `1px solid ${theme.palette.brandGrey[20]}`,
}))

export const StyledTitle = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  width: '60%',
  padding: '8px',
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
}))

export const StyledColumnHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  width: '40%',

  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
}))

export const StyledColumnTitle = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px',
}))

export const StyledMain = clubbiStyled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  marginLeft: '15px',
  marginBottom: '32px',
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
}))

export const StyledSectionName = clubbiStyled('div')(({ theme }) => ({
  fontWeight: 600,
}))

export const StyledDescription = clubbiStyled('div')(({ theme }) => ({
  width: '60%',
  textAlign: 'start',

  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
}))

export const StyledEan = clubbiStyled('div')(({ theme }) => ({
  color: theme.palette.brandGrey[60],
  fontSize: '12px',
}))

export const StyledTotalPrice = clubbiStyled('div')(() => ({
  fontWeight: 600,
  paddingRight: '8px',
}))

export const StyledTotalPriceContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  padding: '8px',
  border: `1px solid ${theme.palette.brandGrey[20]}`,
  width: '100%',
  justifyContent: 'space-between',
}))
