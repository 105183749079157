import { useEffect } from 'react'

import { ShippingTypesSelector } from './ShippingTypesSelector'
import { useAppSelector } from '../../../common/hooks'
import gtm from '../../../common/gtmTracker'

import { StyledSelectionContainer, StyledTitle } from './styles/ShippingTypes.style'
import { useInView } from 'react-intersection-observer'

export const ShippingTypes = () => {
  const { ref, inView } = useInView({ threshold: 0.6 })
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)

  useEffect(() => {
    if (inView) {
      gtm.triggerShippingTypesImpression(merchantCode, clubberEmail)
    }
  }, [inView])

  return (
    <StyledSelectionContainer ref={ref}>
      <StyledTitle>Frete</StyledTitle>
      <ShippingTypesSelector />
    </StyledSelectionContainer>
  )
}
